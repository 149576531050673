<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>客户端API</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}<span
                        v-if="api_p_info.name != ''">【{{ api_p_info.name }}】</span>
                </h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button icon="el-icon-refresh-left" size="medium" type="primary" @click="reload">返回
                        </el-button>
                        <el-button v-if="is_auth('home.it.saveapi')" icon="el-icon-plus" size="medium"
                                   type="primary" @click="addPage">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    v-loading="loading"
                    size="medium"
                    :data="tableData"
                    border
                    style="width: 100%">
                <el-table-column prop="name" label="名称" width="180"></el-table-column>
                <el-table-column prop="remarks" label="描述" width="320">
                    <template slot-scope="scope">
                        <span v-if="scope.row.remarks == ''">-</span>
                        <span v-else>{{ scope.row.remarks }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="api_name" label="api_name" width="240">
                    <template slot-scope="scope">
                        <span v-if="scope.row.api_name == ''">-</span>
                        <span v-else>{{ scope.row.api_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="is_login" label="是否需要登录" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_login == 1">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderby" label="排序（升序）" width="120"></el-table-column>
                <el-table-column prop="state" label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state == -1" style="color: #fa541c;">删除</span>
                        <span v-if="scope.row.state == 0">初始</span>
                        <span v-if="scope.row.state == 1" style="color: #40a9ff;">正常</span>
                        <span v-if="scope.row.state == 2" style="color: #fa8c16;">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column prop="app_type" label="系统">
                    <template slot-scope="scope">
                        <span v-if="scope.row.app_type == 3" style="color: #73d13d;">客户端</span>
                        <span v-if="scope.row.app_type == 4" style="color: #13c2c2;">教练端</span>
                    </template>
                </el-table-column>
                <el-table-column prop="date" label="操作" width="280" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="gotolink(scope.row)">查看下一层</el-button>
                        <el-button v-if="is_auth('home.it.saveapi')" size="mini" @click="modify(scope.row)">修改
                        </el-button>
                        <el-button v-if="is_auth('home.it.getapidoc')" size="mini" @click="getapidoc(scope.row)">
                            查看api文档
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--api文档-->
            <el-dialog v-if="is_auth('home.it.getapidoc')"
                       :title="apidoc.name"
                       :visible.sync="seeapidoc"
                       width="80%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <mavon-editor style="min-height: 600px"
                              @save="saveapidoc(1)"
                              v-model="apidoc.content"
                              :boxShadow="false"
                />
                <el-row style="margin: 10px 0px;">
                    <el-button v-if="is_auth('home.it.saveapidoc')" size="medium" type="primary" @click="saveapidoc">保
                        存
                    </el-button>
                    <el-button size="medium" @click="seeapidoc = false">取 消</el-button>
                </el-row>
            </el-dialog>
            <!--编辑-->
            <el-dialog v-if="is_auth('home.it.savecontrol')"
                       title="编辑权限"
                       :visible.sync="editPage"
                       width="80%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <el-row>
                    <el-form size="medium" ref="form" label-width="120px">
                        <el-col :span="24">
                            <el-form-item label="父级权限">
                                <span v-if="api_p_info.name != ''">【{{
                                        api_p_info.name
                                    }}】</span>
                                <span v-else>【0】</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="父级id">
                                <el-input v-model="info.api_uuid_p" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="名称">
                                <el-input v-model="info.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="权限描述">
                                <el-input v-model="info.remarks"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="api_name">
                                <el-input v-model="info.api_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="是否需要登录">
                                <el-input v-model="info.is_login"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="排序，升序">
                                <el-input v-model="info.orderby"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="状态">
                                <el-input v-model="info.state"></el-input>
                            </el-form-item>
                        </el-col>

                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="save">确 定</el-button>
                    <el-button size="medium" @click="editPage = false">取 消</el-button>
                </el-row>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import {mavonEditor} from 'mavon-editor'

export default {
    components: {
        'mavon-editor': mavonEditor
    },
    data() {
        return {
            updateing: false,
            seeapidoc: false,
            page_name: '客户端API',
            loading: true,
            api_uuid_p: '0',
            editPage: false,
            info: {},
            // 列表内容
            tableData: [],
            apidoc: {},
            api_p_info: {
                name: '',
                api_uuid: '0',
            }
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
        this.getlist()     // 获取列表数据
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 刷新
        reload() {
            location.reload()
        },
        // 初始化
        init() {
            this.info = {
                api_uuid: '',
                api_uuid_p: '0',
                name: '',
                remarks: '',
                api_name: '',
                is_login: '1',
                orderby: '999',
                state: '1',
                app_type: '3'
            }
            // api文档内容
            this.apidoc = {
                name: '',
                uuid: '',
                content: '',
            }
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: 'home.it.getapi',
                token: this.Tool.get_l_cache('token'),
                app_type: '3',
                api_uuid_p: this.api_uuid_p,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                } else {
                    this.tableData = []
                    this.$message.error(json.message);
                }
            })
        },
        //修改
        modify(row) {
            this.info = row
            this.editPage = true
        },
        // 添加
        addPage() {
            this.init()
            this.info.api_uuid_p = this.api_p_info.api_uuid
            this.editPage = true
        },
        // 查看
        gotolink(row) {
            this.api_p_info.name = row.name
            this.api_p_info.api_uuid = row.api_uuid
            this.api_uuid_p = row.api_uuid
            this.getlist();
        },
        // 保存
        save() {
            let postdata = {
                api_name: 'home.it.saveapi',
                token: this.Tool.get_l_cache('token'),
                form: this.info
            }
            // console.log('请求数据', postdata);
            this.Tool.post_data('oss', postdata, (json) => {
                // console.log('返回数据', json)
                if (json.code === 0) {
                    this.editPage = false

                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 获取文档
        getapidoc(data) {
            let postdata = {
                api_name: "home.it.getapidoc",
                token: this.Tool.get_l_cache('token'),
                uuid: data.api_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.seeapidoc = true
                this.loading = false
                this.apidoc = {
                    name: `接口文档【${data.name}】`,
                    uuid: data.api_uuid,
                    content: json.content,
                }
            })
        },
        // 保存文档
        saveapidoc(noclose) {
            let postdata = {
                api_name: "home.it.saveapidoc",
                token: this.Tool.get_l_cache('token'),
                uuid: this.apidoc.uuid,
                content: this.apidoc.content
            }
            if (noclose !== 1) {
                this.seeapidoc = false
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-table th > .cell {
    display: inline-block;
    white-space: nowrap;
    word-break: keep-all;
    text-overflow: unset;
}
</style>
